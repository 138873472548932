<template>
  <div class="page">
    <top-bar/>
    <div class="list">
      <div class="list-item" v-for="(item, index) in list" :key="index">
        <!-- <div>{{item.ItemName}}</div> -->
        <div class="btm">
          <div class="tip">
            <div style="color: #333">{{item.ItemName}}</div>
            <!-- <span v-if="item.count">{{item.count}}异常项</span> -->
            <!-- <span v-else style="color: #00af67;">无异常</span> -->
          </div>
          <div class="btn" @click="handleInfo(item.SerialNo)">查看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
export default {
  data () {
    return {
      list: []
    }
  },
  methods: {
    getList () {
      api.get('report/result/item', { serial_no: this.$route.query.no }).then(res => {
        if (res) {
          this.list = res.data
        }
      })
    },
    handleInfo (no) {
      this.$router.push({
        path: '/info',
        query: { no }
      })
    }
  },
  created () {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.list {
  padding: 30px;
  height: 100%;
  background: #F9FAFA;
  overflow: auto;
  .list-item {
    margin-bottom: 36px;
    padding: 24px 36px;
    background-color: #fff;
    border-radius: 4px;
    color: #666666;
    font-size: 16px;
    .btm {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      // border-top: 1px solid #ccc;
    }
    .btn {
      background-color: #00AF67;
      height: 58px;
      padding: 0 26px;
      line-height: 58px;
      border-radius: 26px;
      color: #fff;
    }
    .tip {
      color: #FF0000;
    }
  }
}
</style>
